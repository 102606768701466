import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { Context } from '@service/context.service';
import { Column } from '@shared/table/column';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from 'primeng/api';
import { TableComponent } from '@shared/table/table.component';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { TacsDocumentMetadata } from '@entity/tacsDocument';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { getMimeTypeIcon } from './document-storage.feature';
import { downloadFromUrl } from '../download';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-file-storage',
  standalone: true,
  imports: [
    ActionButtonDirective,
    DialogModule,
    ProgressSpinnerModule,
    SharedModule,
    TableComponent,
    ToolbarButtonDirective,
    TranslateModule,
    FileUploadModule,
  ],
  templateUrl: './document-storage.component.html',
  styleUrl: './document-storage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentStorageComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  columns: Column[] = [];
  documents: TacsDocumentMetadata[] = [];
  uploading = false;
  deleting = false;

  @ViewChild('fileUpload') private fileUpload!: FileUpload;

  constructor(
    private anwenderbetriebService: AnwenderbetriebService,
    injector: Injector
  ) {
    super(injector, Context.Anwenderbetrieb, 'MENU.DATENABLAGE');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
  }

  add(): void {
    this.navigate(['new']);
  }

  delete(file: TacsDocumentMetadata): void {
    this.confirm({
      header: this.translate('DATENABLAGE.DELETE_HEADER'),
      message: this.translate('DATENABLAGE.DELETE_MESSAGE', {
        name: file.name,
      }),
      accept: () => {
        this.deleting = true;
        this.markForCheck();
        this.anwenderbetriebService
          .deleteDocument(file.anwenderbetriebId, file.id)
          .pipe(finalize(() => (this.deleting = false)))
          .subscribe({
            next: (success) => {
              if (success) {
                this.documents = this.documents.filter((d) => d.id !== file.id);
                this.showSuccessMessage(this.translate('DATENABLAGE.DOCUMENT_DELETED'));
              } else {
                this.showErrorMessage(this.translate('DATENABLAGE.DOCUMENT_DELETE_FAILED'));
              }
            },
            error: () => {
              this.showErrorMessage(this.translate('DATENABLAGE.DOCUMENT_DELETE_FAILED'));
            },
          });
      },
    });
  }

  uploadFiles($event: FileUploadHandlerEvent): void {
    this.uploading = true;
    this.anwenderbetriebService
      .uploadDocuments(this.getAnwenderbetriebId()!, $event.files)
      .pipe(
        finalize(() => {
          this.fileUpload.clear();
          this.uploading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.documents = [...data.map(this.transformRow), ...this.documents];
          this.showSuccessMessage(this.translate('DATENABLAGE.DOCUMENT_UPLOADED'));
        },
        error: () => {
          this.showErrorMessage(this.translate('DATENABLAGE.DOCUMENT_UPLOAD_FAILED'));
        },
      });
  }

  download(row: TacsDocumentMetadata): void {
    this.anwenderbetriebService.downloadDocument(row.anwenderbetriebId, row.id).subscribe({
      next: (data) => {
        if (data.sasUrl) {
          downloadFromUrl(data.sasUrl.toString(), row.name);
        } else {
          this.showErrorMessage(this.translate('DATENABLAGE.DOWNLOAD_FAILED'));
        }
      },
      error: () => {
        this.showErrorMessage(this.translate('DATENABLAGE.DOWNLOAD_FAILED'));
      },
    });
  }

  protected onLanguageChange(): void {
    this.setColumns();
  }

  protected onAnwenderbetriebIdChange(id: number | null): void {
    if (id == null) {
      this.documents = [];
      this.markForCheck();
      return;
    }
    this.startLoading();
    this.anwenderbetriebService.getDocuments(id).subscribe((data) => {
      this.documents = data.map(this.transformRow);
      this.markForCheck();
      this.loadingDone();
    });
  }

  private transformRow = (doc: TacsDocumentMetadata): TacsDocumentMetadata => ({
    ...doc,
    name: decodeURI(doc.name),
    uploadedByUserName: decodeURI(doc.uploadedByUserName),
    _html_type: this.contentTypeToHtml(doc.type),
    canDelete: this.canDelete(doc),
  });

  private canDelete(doc: TacsDocumentMetadata): boolean {
    return doc.uploadedByUserId === this.authorizationService.getUserId() || this.isRodixAdmin();
  }

  private setColumns(): void {
    this.columns = [
      { field: 'name', headerKey: 'DATENABLAGE.DATEINAME', minWidth: '150px' },
      {
        field: 'uploadedAt',
        headerKey: 'DATENABLAGE.HOCHGELADEN_AM',
        minWidth: '150px',
        filterType: 'datetime',
      },
      {
        field: 'uploadedByUserName',
        headerKey: 'DATENABLAGE.HOCHGELADEN_VON',
        minWidth: '150px',
      },
      { field: 'type', headerKey: 'DATENABLAGE.DATEITYP', minWidth: '150px', filterType: 'html' },
    ];
  }

  private contentTypeToHtml(contentType: string): string {
    const { iconName, readableName } = getMimeTypeIcon(contentType);
    const readableTranslated = this.translate(readableName);
    return `<span><i class="fa ${iconName}" title="${readableTranslated}"></i>&nbsp;&nbsp;${readableTranslated}</span>`;
  }
}
