export const Icons: Record<string, FileIcon> = {
  Word: { iconName: 'fa-file-word', readableName: 'APPLICATION_NAME.WORD' },
  Excel: { iconName: 'fa-file-excel', readableName: 'APPLICATION_NAME.EXCEL' },
  PowerPoint: { iconName: 'fa-file-powerpoint', readableName: 'APPLICATION_NAME.POWERPOINT' },
  PDF: { iconName: 'fa-file-pdf', readableName: 'APPLICATION_NAME.PDF' },
  CSV: { iconName: 'fa-file-csv', readableName: 'APPLICATION_NAME.CSV' },
  XML: { iconName: 'fa-file-xml', readableName: 'APPLICATION_NAME.XML' },
  Image: { iconName: 'fa-file-image', readableName: 'APPLICATION_NAME.IMAGE' },
  Audio: { iconName: 'fa-file-audio', readableName: 'APPLICATION_NAME.AUDIO' },
  Video: { iconName: 'fa-file-video', readableName: 'APPLICATION_NAME.VIDEO' },
  Text: { iconName: 'fa-file-text', readableName: 'APPLICATION_NAME.TEXT' },
  JSON: { iconName: 'fa-file-code', readableName: 'APPLICATION_NAME.JSON' },
  Archive: { iconName: 'fa-file-archive', readableName: 'APPLICATION_NAME.ARCHIVE' },
  Default: { iconName: 'fa-file', readableName: 'APPLICATION_NAME.FILE' },
};

export type FileIcon = { iconName: string; readableName: string };

export const getMimeTypeIcon = (mimeType: string): FileIcon => {
  const iconNameMapping: Record<string, FileIcon> = {
    image: Icons.Image,
    audio: Icons.Audio,
    video: Icons.Video,
    'application/pdf': Icons.PDF,
    'application/msword': Icons.Word,
    'application/vnd.ms-word': Icons.Word,
    'application/vnd.oasis.opendocument.text': Icons.Word,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': Icons.Word,
    'application/vnd.ms-excel': Icons.Excel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': Icons.Excel,
    'application/vnd.oasis.opendocument.spreadsheet': Icons.Excel,
    'application/vnd.ms-powerpoint': Icons.PowerPoint,
    'application/vnd.openxmlformats-officedocument.presentationml': Icons.PowerPoint,
    'application/vnd.oasis.opendocument.presentation': Icons.PowerPoint,
    'text/csv': Icons.CSV,
    'text/xml': Icons.XML,
    'text/plain': Icons.Text,
    'application/json': Icons.JSON,
    'application/gzip': Icons.Archive,
    'application/zip': Icons.Archive,
    'application/zlib': Icons.Archive,
    'application/x-tar': Icons.Archive,
    'application/vnd.rar': Icons.Archive,
    'application/x-7z-compressed': Icons.Archive,
  };

  return iconNameMapping[mimeType] ?? startsWith(iconNameMapping, mimeType) ?? Icons.Default;
};

const startsWith = (
  iconNameMapping: Record<string, FileIcon>,
  mimeType: string
): { iconName: string; readableName: string } | undefined => {
  return Object.entries(iconNameMapping).find(([key]) => mimeType.startsWith(key))?.[1];
};
