export function downloadBlob(blob: Blob, filename: string): void {
  const blobURL = window.URL.createObjectURL(blob);
  downloadFromUrl(blobURL, filename);
}

export function downloadFromUrl(url: string, filename: string): void {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
  }, 100);
}
