import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  static readonly RodixAdmin = 'RodixAdmin';
  private static readonly SuperUserKomplett = 'SuperUserKomplett';
  private static readonly KISPartner = 'KIS-partner';
  private static readonly Softwarepartner = 'Softwarepartner';
  private static readonly User = 'User';

  constructor(private oauthService: OAuthService) {}

  isManager(): boolean {
    return this.hasOneOfTheRoles([AuthorizationService.RodixAdmin, AuthorizationService.SuperUserKomplett]);
  }

  isImportControllingManager(): boolean {
    return this.hasOneOfTheRoles([AuthorizationService.RodixAdmin, AuthorizationService.SuperUserKomplett]);
  }

  hasRole(role: string): boolean {
    return this.getRoles().includes(role);
  }

  isBetaTester(): boolean {
    const identityClaims = this.oauthService.getIdentityClaims();
    if (!identityClaims) {
      return false;
    }
    return !!identityClaims.betatester;
  }

  hasOnlyRole(role: string): boolean {
    const roles = this.getRoles();
    return roles.length === 1 && roles.includes(role);
  }

  hasOneOfTheRoles(roles: string[]): boolean {
    return roles.some((role) => this.hasRole(role));
  }

  isRodixAdmin(): boolean {
    return this.hasRole(AuthorizationService.RodixAdmin);
  }

  isSoftwarepartner(): boolean {
    return this.hasRole(AuthorizationService.Softwarepartner);
  }

  isKISPartner(): boolean {
    return this.hasRole(AuthorizationService.KISPartner);
  }

  hasOnlyUserRole(): boolean {
    return this.hasOnlyRole(AuthorizationService.User);
  }

  getFullName(): string {
    const identityClaims = this.oauthService.getIdentityClaims();
    return (identityClaims?.name as string) ?? '';
  }

  getUserId(): string {
    const identityClaims = this.oauthService.getIdentityClaims();
    if (!identityClaims.sub) {
      throw new Error('No sub claim found in identity token, cannot determine user id');
    }
    return identityClaims.sub as string;
  }

  private getRoles(): string[] {
    const identityClaims = this.oauthService.getIdentityClaims();
    if (!identityClaims) {
      return [];
    }
    const roles = identityClaims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] as string[];
    if (!roles) {
      return [];
    }
    return roles;
  }
}
