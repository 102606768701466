import { Injectable } from '@angular/core';
import { Mandant } from '@entity/mandant';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { forkJoin, from, Observable, switchMap } from 'rxjs';
import { Anwenderbetrieb, AnwenderbetriebPayload } from '@entity/anwenderbetrieb';
import { Organisation } from '@entity/organisation';
import { BlobMetadata, TacsDocument, TacsDocumentMetadata } from '@entity/tacsDocument';
import { map } from 'rxjs/operators';
import { BlockBlobClient } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root',
})
export class AnwenderbetriebService {
  private readonly baseUrl = `api/1.0/anwenderbetriebe`;

  constructor(private httpClient: HttpClient) {}

  getAllAnwenderbetriebe(
    includeMandant = false,
    includeLicenseCount = false,
    focusYear?: number
  ): Observable<Anwenderbetrieb[]> {
    let params = new HttpParams()
      .append('includeMandant', includeMandant)
      .append('includeLicenseCount', includeLicenseCount);
    if (focusYear) {
      params = params.append('focusYear', focusYear);
    }
    return this.httpClient.get<Anwenderbetrieb[]>(this.baseUrl, { params });
  }

  getAnwenderbetrieb(id: number): Observable<Anwenderbetrieb> {
    return this.httpClient.get<Anwenderbetrieb>(`${this.baseUrl}/${id}`);
  }

  updateAnwenderbetrieb(id: number, anwenderbetrieb: AnwenderbetriebPayload): Observable<Anwenderbetrieb> {
    return this.httpClient.put<Anwenderbetrieb>(`${this.baseUrl}/${id}`, anwenderbetrieb);
  }

  createAnwenderbetrieb(anwenderbetrieb: AnwenderbetriebPayload): Observable<Anwenderbetrieb> {
    return this.httpClient.post<Anwenderbetrieb>(`${this.baseUrl}`, anwenderbetrieb);
  }

  getMandants(anwenderbetriebId: string | number): Observable<Mandant[]> {
    const params = new HttpParams().append('full', 'true');
    return this.httpClient.get<Mandant[]>(`${this.baseUrl}/${anwenderbetriebId}/mandanten`, { params });
  }

  deleteAnwenderbetrieb(id: number, dataOnly = true): Observable<unknown> {
    const params = new HttpParams().append('dataOnly', dataOnly + '');
    return this.httpClient.delete(`${this.baseUrl}/${id}`, { params });
  }

  getOrganisationalUnits(anwenderbetriebId: number): Observable<Organisation[]> {
    return this.httpClient.get<Organisation[]>(`${this.baseUrl}/${anwenderbetriebId}/organisational-units`);
  }

  getActivePersonnelCategories(anwenderbetriebId: number): Observable<{ [tacsId: string]: string }> {
    return this.httpClient.get<{ [tacsId: string]: string }>(
      `${this.baseUrl}/${anwenderbetriebId}/active-personnel-categories`
    );
  }

  downloadLicenseProof(anwenderbetriebId: number, focusYear: number): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().append('focusYear', focusYear);
    return this.httpClient.get(`${this.baseUrl}/${anwenderbetriebId}/license-proof/`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getDocuments(anwenderbetriebId: number): Observable<TacsDocumentMetadata[]> {
    return this.httpClient.get<TacsDocumentMetadata[]>(`${this.baseUrl}/${anwenderbetriebId}/documents`);
  }

  downloadDocument(anwenderbetriebId: number, documentId: string): Observable<TacsDocument> {
    return this.httpClient.get<TacsDocument>(`${this.baseUrl}/${anwenderbetriebId}/documents/${documentId}`);
  }

  deleteDocument(anwenderbetriebId: number, documentId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.baseUrl}/${anwenderbetriebId}/documents/${documentId}`);
  }

  uploadDocuments(anwenderbetriebId: number, files: File[]): Observable<TacsDocumentMetadata[]> {
    return forkJoin(
      files.map((file) => {
        const params = new HttpParams().append('documentName', file.name).append('contentType', file.type);
        return this.httpClient
          .get<TacsDocument>(`${this.baseUrl}/${anwenderbetriebId}/documents/upload-url`, {
            params,
          })
          .pipe(
            switchMap((doc: TacsDocument) => {
              const metadata: BlobMetadata = {
                id: doc.id,
                anwenderbetrieb_id: doc.anwenderbetriebId.toString(),
                betrieb_id: doc.betriebId.toString(),
                name: encodeURI(doc.name),
                uploaded_by_user_id: doc.uploadedByUserId,
                uploaded_by_user_name: encodeURI(doc.uploadedByUserName),
              };
              return from(
                new BlockBlobClient(doc.sasUrl.toString()).uploadData(file, {
                  blobHTTPHeaders: { blobContentType: file.type },
                  metadata: metadata,
                })
              ).pipe(map(() => doc));
            })
          );
      })
    );
  }
}
