import { ChangeDetectionStrategy, Component, computed, OnDestroy, Signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Column } from '@shared/table/column';
import { Variable, VariableEntry } from '@entity/variable';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { FormTimePeriodComponent, TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { ContextService } from '@service/context.service';
import { AuthorizationService } from '@service/authorization.service';
import { Language, LanguageFormGroup } from '@entity/language';
import { finalize, Subscription } from 'rxjs';
import { VariableService } from '@service/variable.service';
import { ButtonModule } from 'primeng/button';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-variable-entries',
  templateUrl: './variable-entries.component.html',
  styleUrls: ['./variable-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    SharedModule,
    TranslateModule,
    FormTextInputComponent,
    FormSwitchComponent,
    FormDateInputComponent,
    FormNumberInputComponent,
    FormTimePeriodComponent,
    FormButtonBarComponent,
    ButtonModule,
  ],
})
export class VariableEntriesComponent implements OnDestroy {
  columns: Column[] = [];
  variable!: Variable;
  form!: FormGroup<{
    tacsId: FormControl<string | undefined>;
    parentName: FormControl<string | undefined>;
    name: FormControl<string | undefined>;
    description: FormControl<string | undefined>;
    comment: FormControl<string | undefined>;
    isLocked: FormControl<boolean>;
    isCustomNameApproved: FormControl<boolean | undefined>;
    isMeasure: FormControl<boolean | undefined>;
    foreignCode: FormControl<string | undefined>;
    validFrom: FormControl<Date | undefined>;
    validTo: FormControl<Date | undefined>;
    sortIndex: FormControl<number | undefined>;
    parentSortIndex: FormControl<number | undefined>;
    entries: FormControl<TimePeriodItem[]>;
    customName: FormGroup<LanguageFormGroup>;
    customComment: FormGroup<LanguageFormGroup>;
    leistungsgewicht: FormControl<number | undefined>;
  }>;
  languages: Language[] = [];
  subscriptions: Subscription[] = [];
  submitting = false;
  active = false;
  isMeasure!: Signal<boolean>;
  private readonly isMeasureToggled!: Signal<boolean | undefined>;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    private contextService: ContextService,
    private variableService: VariableService
  ) {
    this.variable = this.config.data.variable;
    this.active = this.config.data.active;
    this.languages = this.contextService.getMandantLanguages();
    this.form = this.formBuilder.nonNullable.group(
      {
        tacsId: new FormControl({ value: this.variable.tacsId, disabled: true }, { nonNullable: true }),
        parentName: new FormControl<string | undefined>(
          {
            value: this.variable.parentTacsId + ' ' + this.variable.parentName,
            disabled: true,
          },
          { nonNullable: true }
        ),
        name: new FormControl(
          {
            value: this.variable.name,
            disabled: !this.variable.isBetriebsVariable,
          },
          { validators: Validators.required, nonNullable: true }
        ),
        description: new FormControl(
          {
            value: this.variable.description,
            disabled: !this.variable.isBetriebsVariable,
          },
          { nonNullable: true }
        ),
        comment: new FormControl(
          {
            value: this.variable.comment,
            disabled: !this.variable.isBetriebsVariable,
          },
          { nonNullable: true }
        ),
        isCustomNameApproved: new FormControl(
          {
            value: this.variable.isCustomNameApproved,
            disabled: !this.authorizationService.isRodixAdmin(),
          },
          { nonNullable: true }
        ),
        isLocked: new FormControl(
          {
            value: this.variable.isLocked ?? false,
            disabled: !this.authorizationService.isRodixAdmin(),
          },
          { nonNullable: true }
        ),
        isMeasure: new FormControl(
          {
            value: this.variable.isMeasure,
            disabled: !(this.variable.isBetriebsVariable && this.variable.tacsId?.startsWith('2.')),
          },
          { nonNullable: true }
        ),
        foreignCode: new FormControl({ value: this.variable.foreignCode, disabled: true }, { nonNullable: true }),
        validFrom: new FormControl(
          {
            value: this.variable.validFrom,
            disabled: !this.variable.isBetriebsVariable,
          },
          { validators: Validators.required, nonNullable: true }
        ),
        validTo: new FormControl(
          {
            value: this.variable.validTo ?? undefined,
            disabled: !this.variable.isBetriebsVariable,
          },
          { nonNullable: true }
        ),
        sortIndex: new FormControl(
          {
            value: this.variable.sortIndex,
            disabled: !this.variable.isBetriebsVariable || !this.authorizationService.isRodixAdmin(),
          },
          { validators: Validators.required, nonNullable: true }
        ),
        parentSortIndex: new FormControl(
          { value: this.variable.parentSortIndex, disabled: true },
          { nonNullable: true }
        ),
        entries: new FormControl(
          {
            value:
              this.variable.entries?.map(
                (e) =>
                  ({
                    id: e.id,
                    from: e.validFrom,
                    to: e.validTo,
                  }) as TimePeriodItem
              ) || [],
            disabled: !this.authorizationService.isRodixAdmin(),
          },
          { nonNullable: true }
        ),
        customName: this.formBuilder.nonNullable.group({
          De: this.variable.customName?.De ?? undefined,
          Fr: this.variable.customName?.Fr ?? undefined,
          It: this.variable.customName?.It ?? undefined,
        }),
        customComment: this.formBuilder.nonNullable.group({
          De: this.variable.customComment?.De ?? undefined,
          Fr: this.variable.customComment?.Fr ?? undefined,
          It: this.variable.customComment?.It ?? undefined,
        }),
        leistungsgewicht: new FormControl(
          {
            value: this.variable.leistungsgewicht,
            disabled:
              (!this.variable.isBetriebsVariable && !this.variable.isMeasure) || !this.authorizationService.isManager(),
          },
          { nonNullable: true }
        ),
      },
      {
        validators: (form: AbstractControl): ValidationErrors | null => {
          if (
            (form as FormGroup).getRawValue().entries.length === 0 &&
            !this.variable.isBetriebsVariable &&
            !this.authorizationService.isRodixAdmin()
          ) {
            return {
              entries: 'required',
            };
          }
          return null;
        },
      }
    );

    if (this.variable.isBetriebsVariable) {
      this.form.controls.validFrom.valueChanges.subscribe((value) => {
        const timePeriodItems: TimePeriodItem[] = this.form.controls.entries.value;
        if (timePeriodItems.length > 0 && value) {
          timePeriodItems[0].from = value;
        }
        this.form.controls.entries.setValue([...timePeriodItems]);
      });
      this.form.controls.validTo.valueChanges.subscribe((value) => {
        const timePeriodItems: TimePeriodItem[] = this.form.controls.entries.value;
        if (timePeriodItems.length > 0) {
          timePeriodItems[timePeriodItems.length - 1].to = value ?? null;
        }
        this.form.controls.entries.setValue([...timePeriodItems]);
      });
    }

    if (!this.authorizationService.isManager()) {
      this.form.disable();
    }

    this.isMeasureToggled = toSignal(this.form.controls.isMeasure.valueChanges);

    this.isMeasure = computed(() => {
      if (this.isMeasureToggled()) {
        return this.isMeasureToggled() ?? false;
      }
      return this.form.controls.isMeasure.value ?? false;
    });
  }

  close(): void {
    this.ref.close();
  }

  submit(): void {
    this.submitting = true;
    const data: Variable = {
      ...this.variable,
      ...this.form.getRawValue(),
      entries: this.form.getRawValue().entries.map(
        (t: TimePeriodItem) =>
          ({
            id: t.id,
            validFrom: t.from,
            validTo: t.to,
          }) as VariableEntry
      ),
    };

    this.variableService
      .createOrUpdate(this.contextService.getMandantIdInstant(), data)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe((result) => {
        this.ref.close(result);
      });
  }

  isRodixAdmin(): boolean {
    return this.authorizationService.isRodixAdmin();
  }

  delete(): void {
    if (!this.variable.id) {
      return;
    }
    this.variableService.deleteVariable(this.contextService.getMandantIdInstant(), this.variable.id).subscribe(() => {
      this.ref.close({ id: null });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
