import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Column } from '@shared/table/column';
import { Row } from 'exceljs';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollIntoViewDirective } from '../../scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { FocusTrapModule } from 'primeng/focustrap';
import { DatePipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FocusTrapModule,
    NgSwitch,
    NgSwitchCase,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    InputSwitchModule,
    InputNumberModule,
    NgSwitchDefault,
    DropdownModule,
    ScrollIntoViewDirective,
    InputTextModule,
    ButtonModule,
    NgTemplateOutlet,
    RouterLink,
    DatePipe,
    TranslateModule,
  ],
})
export class TableCellComponent implements OnChanges {
  @Input()
  editable = false;

  @Input()
  changed = false;

  @Input()
  error?: string;

  @Input()
  value: AnyValue;

  @Input()
  route?: string;

  @Input()
  url?: string;

  @Input()
  type: 'dateRangeAssignment' | 'percent' | 'time' | 'date' | 'boolean' | 'number' | 'text' | 'integer' | 'datetime' =
    'text';

  @Input()
  options?: SelectItem[];

  @Input()
  required = false;

  @Input()
  column?: Column;

  @Input()
  row?: Row;

  @Output()
  valueChange = new EventEmitter<AnyValue>();

  editModeEnabled = false;
  formControl?: FormControl;
  minDate = new Date(0, 0, 1);
  maxDate = new Date(2999, 11, 31);
  defaultDate = new Date();

  constructor(private elementRef: ElementRef) {}

  private static format(value: number | undefined | null, style: string): string {
    return value !== null && value !== undefined
      ? new Intl.NumberFormat('de-CH', {
          style,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(value)
      : '';
  }

  ngOnChanges(): void {
    this.editModeEnabled = false;
  }

  enableEditMode(): void {
    if (this.column?.fastEdit && this.type === 'boolean') {
      this.value = !this.value;
      this.valueChange.emit(this.value);
      return;
    }

    this.minDate = this.column?.minDate ?? this.minDate;
    this.maxDate = this.column?.maxDate ?? this.maxDate;
    this.defaultDate = this.column?.minDate ?? this.defaultDate;

    this.formControl = new FormControl(null, this.required ? Validators.required : null);
    if (this.column?.onEdit) {
      this.column?.onEdit(this.value, this.column, this.row);
      return;
    }
    if (this.type === 'date') {
      this.formControl.setValue(this.value ? new Date(this.value) : null);
    } else if (this.type === 'time') {
      this.formControl.setValue(
        this.value ? new Date(0, 0, 0, Math.floor(this.value), Math.round((this.value * 60) % 60), 0) : null
      );
    } else if (this.type === 'percent') {
      this.formControl.setValue(this.value ? this.value * 100 : null);
    } else {
      this.formControl.setValue(this.value);
    }
    this.editModeEnabled = true;
    setTimeout(() => {
      const querySelector = this.elementRef.nativeElement.querySelector('input');
      if (querySelector != undefined) {
        querySelector.focus();
        if (this.type === 'number' || this.type === 'percent') {
          querySelector.select();
        }
      }
    });
  }

  getOptionText(): string {
    const selectedOption = this.options?.find((o) => o.value === this.value);

    return selectedOption?.label ?? '';
  }

  cancel(): void {
    this.editModeEnabled = false;
  }

  save(): void {
    const value = this.formControl?.value;

    if (this.type !== 'boolean' && !value && this.required) {
      return;
    }
    if (this.type === 'time') {
      if (value) {
        const hours = value.getHours() + value.getMinutes() / 60;
        this.value = Math.round((hours + Number.EPSILON) * 100) / 100;
      } else {
        this.value = null;
      }
    } else if (this.type === 'percent') {
      this.value = value ? value / 100 : value;
    } else {
      this.value = value;
    }

    this.valueChange.emit(this.value);
    this.cancel();
  }

  formatTime(value?: number): string {
    if (!value) {
      return '';
    }
    const hour = Math.floor(value);
    const minutes = Math.round((value * 60) % 60);
    return `${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
  }

  formatNumber(value?: number): string {
    return TableCellComponent.format(value, 'decimal');
  }

  formatPercent(value?: number): string {
    return TableCellComponent.format(value, 'percent');
  }

  onBlur(): void {
    if (this.column?.fastEdit) {
      this.save();
    }
  }
}
