<form class="grid" novalidate (submit)="submit()" [formGroup]="form">
  @if (!variable.isBetriebsVariable && !active) {
    <p-messages severity="info">
      <ng-template pTemplate>
        <div translate>VARIABLES.VARIABLE_NOT_ACTIVATED</div>
      </ng-template>
    </p-messages>
  }

  @if (variable.isBetriebsVariable) {
    <app-form-text-input id="parentName" label="VARIABLES.PARENT_NAME" [form]="form"></app-form-text-input>
  }

  <app-form-text-input id="tacsId" label="VARIABLES.TACS_ID" [form]="form"></app-form-text-input>

  <app-form-text-input
    id="name"
    [maxLength]="50"
    [form]="form"
    [label]="variable.isBetriebsVariable ? 'GLOBAL.NAME' : 'VARIABLES.NAME'"></app-form-text-input>

  @if (!variable.isBetriebsVariable) {
    @for (language of languages; track language) {
      <app-form-text-input
        [form]="form"
        [id]="'customName.' + language"
        [label]="'GLOBAL.NAME_' + language"></app-form-text-input>
    }
  }

  <app-form-text-input
    id="description"
    [form]="form"
    [label]="variable.isBetriebsVariable ? 'GLOBAL.DESCRIPTION' : 'VARIABLES.DESCRIPTION'"
    [textArea]="true"></app-form-text-input>

  <app-form-text-input
    id="comment"
    [form]="form"
    [label]="variable.isBetriebsVariable ? 'VARIABLES.COMMENT' : 'VARIABLES.COMMENT_PROPOSAL'"
    [textArea]="true"></app-form-text-input>

  @if (!variable.isBetriebsVariable) {
    @for (language of languages; track language) {
      <app-form-text-input
        [form]="form"
        [id]="'customComment.' + language"
        [label]="'VARIABLES.COMMENT_' + language"
        [textArea]="true"></app-form-text-input>
    }
  }

  <app-form-switch id="isLocked" label="VARIABLES.LOCKED" [form]="form"></app-form-switch>

  <app-form-switch id="isCustomNameApproved" label="GLOBAL.APPROVED" [form]="form"></app-form-switch>

  <app-form-switch id="isMeasure" label="VARIABLES.MEASURE" [form]="form"></app-form-switch>

  @if (!variable.isBetriebsVariable) {
    <app-form-text-input id="foreignCode" label="VARIABLES.FOREIGN_CODE" [form]="form"></app-form-text-input>
  }

  @if (variable.isBetriebsVariable) {
    <app-form-date-input id="validFrom" label="GLOBAL.GUELTIG_AB" [form]="form"></app-form-date-input>
  }

  @if (variable.isBetriebsVariable) {
    <app-form-date-input
      id="validTo"
      label="GLOBAL.GUELTIG_BIS"
      [minDate]="form.controls.validFrom.value"
      [form]="form"></app-form-date-input>
  }

  @if (variable.isBetriebsVariable) {
    <app-form-number-input
      id="parentSortIndex"
      label="VARIABLES.PARENT_SORT_INDEX"
      [form]="form"></app-form-number-input>
  }

  <app-form-number-input id="sortIndex" label="VARIABLES.SORT_INDEX" [form]="form"></app-form-number-input>

  <app-form-time-period
    id="entries"
    label="VARIABLES.VALIDITY_PERIOD"
    [form]="form"
    [maxDate]="form.controls.validTo.value"
    [minDate]="form.controls.validFrom.value"></app-form-time-period>

  @if (variable.isBetriebsVariable && isMeasure()) {
    <app-form-number-input
      id="leistungsgewicht"
      label="VARIABLES.LEISTUNGSGEWICHT"
      [form]="form"></app-form-number-input>
  }

  <app-form-button-bar (onCancel)="close()" [saveDisabled]="submitting" [form]="form">
    @if (variable.isBetriebsVariable && isRodixAdmin() && this.variable.id) {
      <p-button
        icon="fal fa-trash"
        styleClass="p-button-danger"
        type="button"
        (onClick)="delete()"
        [label]="'ACTIONS.DELETE' | translate"></p-button>
    }
  </app-form-button-bar>
</form>
