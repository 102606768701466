import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { RoleOptionsResolve } from './users/user-detail/role-options.resolve';
import { UserResolve } from './users/user-detail/user.resolve';
import { DashboardComponent } from './dashbaord/dashboard.component';
import { JobsComponent } from './jobs/jobs.component';
import { BetriebOptionsResolve } from './jobs/betrieb-options.resolve';
import { ServiceAccountsComponent } from './service-accounts/service-accounts.component';
import { ServiceAccountDetailComponent } from './service-accounts/service-account-detail/service-account-detail.component';
import { AuthGuard, AuthRole } from './auth.guard';
import { MandantsComponent } from './mandants/mandants.component';
import { MandantDetailComponent } from './mandants/mandant-detail/mandant-detail.component';
import { MandantResolve } from './mandants/mandant-detail/mandant.resolve';
import { KantoneResolve } from './resolver/kantone.resolve';
import { OrganisationalUnitsComponent } from './organisational-units/organisational-units.component';
import { OrganisationalUnitDetailComponent } from './organisational-units/organisational-unit-detail/organisational-unit-detail.component';
import { OrganisationResolve } from './organisational-units/organisation.resolve';
import { TacsCodeKreisResolve } from './resolver/tacs-code-kreis.resolve';
import { CustomersComponent } from './customers/customers.component';
import { MarketsComponent } from './markets/markets.component';
import { MarketDetailComponent } from './markets/market-detail/market-detail.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetailComponent } from './categories/category-detail/category-detail.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { EmployeesComponent } from './employees/employees.component';
import { AnwenderbetriebeResolve } from './users/anwenderbetriebe.resolve';
import { CategoryResolve } from './categories/category-detail/category.resolve';
import { PersonnelCategoriesComponent } from './personnel-categories/personnel-categories.component';
import { VersionComponent } from './version/version.component';
import { StatisticsCodesComponent } from './statistics-codes/statistics-codes.component';
import { StatisticsCodeDetailComponent } from './statistics-codes/statistics-code-detail/statistics-code-detail.component';
import { MarketResolve } from './markets/market-detail/market.resolve';
import { StatisticsCodeResolve } from './statistics-codes/statistics-code-detail/statistics-code.resolve';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AssignmentRootType } from '@entity/assignment';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { BeneficiaryDetailComponent } from './beneficiaries/beneficiary-detail/beneficiary-detail.component';
import { BeneficiaryResolve } from './beneficiaries/beneficiary-detail/beneficiary.resolve';
import { OrganizationCategoryResolve } from './organisational-units/organisational-unit-detail/organization-category-resolve.service';
import { VariablesComponent } from './variables/variables.component';
import { VariableApprovalsComponent } from './variables/variable-approvals/variable-approvals.component';
import { VariableApprovalDetailComponent } from './variables/variable-approvals/variable-approval-detail/variable-approval-detail.component';
import { VariableApprovalResolve } from './variables/variable-approvals/variable-approval.resolve';
import { PublicHolidaysComponent } from './public-holidays/public-holidays.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { MeasuresComponent } from './variables/measures/measures.component';
import { WorkdaysComponent } from './workdays/workdays.component';
import { PersonnelCostsComponent } from './personnel-costs/personnel-costs.component';
import { TimeCreditsComponent } from './time-credits/time-credits.component';
import { TargetJobPlansComponent } from './target-job-plans/target-job-plans.component';
import { MappingCatalogsComponent } from './mapping-catalogs/mapping-catalogs.component';
import { MappingCatalogDetailComponent } from './mapping-catalogs/mapping-catalog-detail/mapping-catalog-detail.component';
import { TimeBudgetsComponent } from './time-budgets/time-budgets.component';
import { VariablesComponent as MainDataVariablesComponent } from './main-data/variables/variables.component';
import { VariableDetailComponent } from './main-data/variables/variable-detail/variable-detail.component';
import { VariableResolve } from './main-data/variables/variable-detail/variable.resolve';
import { AnwenderbetriebeComponent } from './anwenderbetriebe/anwenderbetriebe.component';
import { AnwenderbetriebDetailComponent } from './anwenderbetriebe/anwenderbetrieb-detail/anwenderbetrieb-detail.component';
import { CatalogsResolve } from './anwenderbetriebe/anwenderbetrieb-detail/catalogs.resolve';
import { AnwenderbetriebResolve } from './anwenderbetriebe/anwenderbetrieb-detail/anwenderbetrieb.resolve';
import { MarketsResolve } from './resolver/markets.resolve';
import { JobControllingComponent } from './job-controlling/job-controlling.component';
import { ReportStatisticsComponent } from './reporting/report-statistics/report-statistics.component';
import { LoginStatisticsComponent } from './reporting/login-statistics/login-statistics.component';
import { DocumentStorageComponent } from './document-storage/document-storage.component';

const betriebskategorie = 7;
export const routes: Routes = [
  {
    path: '',
    redirectTo: '/jobs',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'jobs',
    component: JobsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
    resolve: {
      betriebOptions: BetriebOptionsResolve,
    },
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'users/new',
    component: UserDetailComponent,
    resolve: {
      anwenderbetrieb: AnwenderbetriebeResolve,
      roleOptions: RoleOptionsResolve,
    },
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    resolve: {
      anwenderbetrieb: AnwenderbetriebeResolve,
      roleOptions: RoleOptionsResolve,
      user: UserResolve,
    },
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'anwenderbetriebe',
    component: AnwenderbetriebeComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'anwenderbetriebe/new',
    component: AnwenderbetriebDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      catalogs: CatalogsResolve,
    },
  },
  {
    path: 'anwenderbetriebe/:id',
    component: AnwenderbetriebDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      anwenderbetrieb: AnwenderbetriebResolve,
      catalogs: CatalogsResolve,
    },
  },
  {
    path: 'service-accounts',
    component: ServiceAccountsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'service-accounts/new',
    component: ServiceAccountDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'mandants',
    component: MandantsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'mandants/new',
    component: MandantDetailComponent,
    canActivate: [AuthGuard],
    data: { ...AuthRole.MANAGER, variablenartId: betriebskategorie },
    resolve: {
      kantone: KantoneResolve,
      markets: MarketsResolve,
      tacsCodeKreis: TacsCodeKreisResolve,
    },
  },
  {
    path: 'mandants/:id',
    component: MandantDetailComponent,
    canActivate: [AuthGuard],
    data: { ...AuthRole.MANAGER, variablenartId: betriebskategorie },
    resolve: {
      mandant: MandantResolve,
      kantone: KantoneResolve,
      markets: MarketsResolve,
      tacsCodeKreis: TacsCodeKreisResolve,
    },
  },
  {
    path: 'organisational-units',
    component: OrganisationalUnitsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER,
  },
  {
    path: 'organisational-units/:id',
    component: OrganisationalUnitDetailComponent,
    canActivate: [AuthGuard],
    data: { ...AuthRole.MANAGER },
    resolve: {
      organisation: OrganisationResolve,
      organizationCategories: OrganizationCategoryResolve,
    },
  },
  {
    path: 'datenablage',
    component: DocumentStorageComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'markets',
    component: MarketsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'markets/new',
    component: MarketDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'markets/:id',
    component: MarketDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      market: MarketResolve,
    },
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      markets: MarketsResolve,
    },
  },
  {
    path: 'categories/new',
    component: CategoryDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      markets: MarketsResolve,
    },
  },
  {
    path: 'categories/:id',
    component: CategoryDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
    resolve: {
      category: CategoryResolve,
      markets: MarketsResolve,
    },
  },
  {
    path: 'personal-categories',
    component: PersonnelCategoriesComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER,
  },
  {
    path: 'variables/overview',
    component: VariablesComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER_AND_SOFTWAREPARTNER,
    },
  },
  {
    path: 'variables/measures',
    component: MeasuresComponent,
  },
  {
    path: 'variables/assignments',
    component: AssignmentsComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER_AND_SOFTWAREPARTNER,
      type: AssignmentRootType.VARIABLE,
    },
  },
  {
    path: 'variables/approvals',
    component: VariableApprovalsComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER,
    },
  },
  {
    path: 'variables/approvals/:id',
    component: VariableApprovalDetailComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER,
    },
    resolve: {
      variableApproval: VariableApprovalResolve,
    },
  },
  {
    path: 'version',
    component: VersionComponent,
  },
  {
    path: 'statistics-codes/assignments',
    component: AssignmentsComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER_AND_SOFTWAREPARTNER,
      type: AssignmentRootType.STATISTICS_CODE,
    },
  },
  {
    path: 'statistics-codes/overview',
    component: StatisticsCodesComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER,
  },
  {
    path: 'statistics-codes/overview/new',
    component: StatisticsCodeDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'statistics-codes/overview/:id',
    component: StatisticsCodeDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
    resolve: {
      statisticsCode: StatisticsCodeResolve,
    },
  },
  {
    path: 'beneficiaries/overview',
    component: BeneficiariesComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER,
  },
  {
    path: 'beneficiaries/assignments',
    component: AssignmentsComponent,
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.MANAGER_AND_SOFTWAREPARTNER,
      type: AssignmentRootType.BENEFICIARY,
    },
  },
  {
    path: 'beneficiaries/overview/new',
    component: BeneficiaryDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'beneficiaries/overview/:id',
    component: BeneficiaryDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
    resolve: {
      beneficiary: BeneficiaryResolve,
    },
  },
  {
    path: 'public-holidays',
    component: PublicHolidaysComponent,
    canActivate: [AuthGuard],
    data: AuthRole.RODIX_ADMIN,
  },
  {
    path: 'holidays',
    component: HolidaysComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'workdays',
    component: WorkdaysComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'personnel-costs',
    component: PersonnelCostsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'time-credits',
    component: TimeCreditsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'target-job-plans',
    component: TargetJobPlansComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'mapping-catalogs',
    component: MappingCatalogsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER_AND_KIS_PARTNER,
  },
  {
    path: 'admin-mapping-catalogs',
    component: MappingCatalogsComponent,
    canActivate: [AuthGuard],
    data: { ...AuthRole.RODIX_ADMIN, isMandantSection: true },
  },
  {
    path: 'mapping-catalogs/:id',
    component: MappingCatalogDetailComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER_AND_SOFTWAREPARTNER_AND_KIS_PARTNER,
  },
  {
    path: 'admin-mapping-catalogs/:id',
    component: MappingCatalogDetailComponent,
    canActivate: [AuthGuard],
    data: { ...AuthRole.RODIX_ADMIN, isMandantSection: true },
  },
  {
    path: 'time-budgets',
    component: TimeBudgetsComponent,
    canActivate: [AuthGuard],
    data: AuthRole.MANAGER,
  },
  {
    path: 'main-data',
    children: [
      {
        path: 'variables',
        component: MainDataVariablesComponent,
        canActivate: [AuthGuard],
        data: {
          ...AuthRole.RODIX_ADMIN,
        },
        resolve: {
          markets: MarketsResolve,
        },
      },
      {
        path: 'variables/new',
        component: VariableDetailComponent,
        canActivate: [AuthGuard],
        data: {
          ...AuthRole.RODIX_ADMIN,
        },
        resolve: {
          markets: MarketsResolve,
          parent: VariableResolve,
        },
      },
      {
        path: 'variables/:id',
        component: VariableDetailComponent,
        canActivate: [AuthGuard],
        data: {
          ...AuthRole.RODIX_ADMIN,
        },
        resolve: {
          variable: VariableResolve,
          markets: MarketsResolve,
        },
      },
    ],
  },
  {
    path: 'job-controlling',
    component: JobControllingComponent,
    canActivate: [AuthGuard],
    resolve: {
      clientOptions: BetriebOptionsResolve,
    },
    data: {
      ...AuthRole.RODIX_ADMIN,
    },
  },
  {
    path: 'reporting',
    canActivate: [AuthGuard],
    data: {
      ...AuthRole.RODIX_ADMIN,
    },
    children: [
      {
        path: 'report-statistics',
        component: ReportStatisticsComponent,
      },
      {
        path: 'login-statistics',
        component: LoginStatisticsComponent,
      },
    ],
  },
];

export const AppRoutes = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
