<app-table
  filename="MENU.DATENABLAGE"
  [actionColumnWidth]="300"
  [columns]="columns"
  [loading]="loading"
  [value]="documents">
  <p-fileUpload
    #fileUpload
    customAction
    mode="basic"
    (uploadHandler)="uploadFiles($event)"
    [auto]="true"
    [customUpload]="true"
    [multiple]="true"
    [chooseLabel]="'DATENABLAGE.ADD_FILE' | translate" />
  <ng-template
    appActionButton
    icon="fal fa-arrow-down"
    labelKey="ACTIONS.DOWNLOAD"
    (onClick)="download($event.row)"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-trash"
    labelKey="ACTIONS.DELETE"
    type="danger"
    displayIf="canDelete"
    (onClick)="delete($event.row)"></ng-template>
</app-table>

<p-dialog [closable]="false" [contentStyle]="{ height: '135px' }" [modal]="true" [visible]="uploading || deleting">
  <p-header>
    @if (uploading) {
      {{ 'DATENABLAGE.DOCUMENT_UPLOADING' | translate }}
    } @else if (deleting) {
      {{ 'DATENABLAGE.DOCUMENT_DELETING' | translate }}
    }
  </p-header>
  <div class="flex justify-content-center">
    <p-progressSpinner styleClass="w-4rem"></p-progressSpinner>
  </div>
</p-dialog>
